import { ApiRoute } from "common/ApiRoute"
import Loader from "common/Loader"
import useWindowDimensions from "common/useWindowDimensions"
import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Table } from "reactstrap"

function BarcodeLogTable(props) {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const params = useParams()
  // const data = props?.data
  const [data, setData] = useState([]) // For storing table data
  const [loading, setLoading] = useState(true) // For managing loading state
  const [error, setError] = useState(null) // For error handling
  const { height, width } = useWindowDimensions()

  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }
  console.log(props)
  //   const downloadClickNoticePdf = (file, id) => {
  //     const request = {
  //       token: props?.token,
  //       folder: "CourierPdf",
  //       filename: file,
  //       id: id,
  //     }
  //     DownloadApiCall(request, "download/single-download", "_blank")
  //   }

  // useEffect(() => {
  //   const displayStart = limit * (page - 1)

  //   const requestPayload = {
  //     case_id: atob(params.id),
  //     token: props.token,
  //     page: page,
  //     //   iDisplayStart: displayStart,
  //     //   iDisplayLength: limit,
  //   }
  //   dispatch(getBarcodeLog(requestPayload))
  // }, [page, limit])

  const fetchData = async () => {
    setLoading(true)
    setError(null)

    try {
      const formData = new FormData()
      formData.append("case_id", atob(params.id)) // Example case ID, replace as needed
      formData.append("page", page)
      formData.append("limit", limit)

      formData.append("token", props?.token)

      const response = await fetch(ApiRoute + "/user/barcode-track-log", {
        method: "POST",
        body: formData,
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const result = await response.json()
      setData(result.responseData) // Assuming responseData contains the data array
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [page, limit])

  return (
    <div className="table-responsive">
      <div style={{ height: height - 300, overflow: "scroll" }}>
        <Table size="sm">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Case Id</th>
              <th>Name</th>
              <th>Address</th>
              <th>Barcode</th>
              <th>Date of dispatch</th>
              <th>Created at</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {props?.tableLoading ? (
              <tr>
                <td colSpan={11} className="text-center">
                  <div className="mt-4">
                    <Loader />
                  </div>
                </td>
              </tr>
            ) : data?.length !== 0 ? (
              data?.map((value, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>IN{value.notice_id}</td>
                      <td>{value.name}</td>
                      <td>{value.address}</td>
                      <td>{value.barcode}</td>
                      <td>{value.date_of_dispatch}</td>
                      <td>{value.created_on}</td>
                      <td>{value.status_label}</td>
                    </tr>
                  </>
                )
              })
            ) : (
              <tr>
                <td colSpan={11} className="text-center">
                  <div className="mt-4">No Data Found</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {/* <Table
        height={height - 300}
        data={data}
        wordWrap="break-word"
        headerHeight={50}
        loading={props.tableLoading}
      >
        <Column width={95} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Case ID</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>AWB No</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Type</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Status</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Status on Date</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Status at</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Last Activity</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Reason</HeaderCell>
          <Cell></Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Final Status</HeaderCell>
          <Cell></Cell>
        </Column>
      </Table>
      <Pagination
        className="my-4"
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={props?.count}
        limitOptions={[50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      /> */}
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.CourierLog.items,
  tableLoading: state.CourierLog.loading,
  count: state.CourierLog.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(BarcodeLogTable)
