import FileDownloadIcon from "@rsuite/icons/FileDownload"
import EyeIcon from "@rsuite/icons/legacy/Eye"
import SortUpIcon from "@rsuite/icons/SortUp"
import TrashIcon from "@rsuite/icons/Trash";
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import { ApiRoute, RouteWithoutApi } from "common/ApiRoute"
import { ShowNotification } from "common/ShowNotification"
import useWindowDimensions from "common/useWindowDimensions"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Button, Label } from "reactstrap"
import { ShowSwal } from "common/ShowSwal"
import Swal from "sweetalert2";
import {
  Col,
  IconButton,
  Input,
  Pagination,
  Row,
  SelectPicker,
  Table,
} from "rsuite"
import { getAutoReport } from "store/actions"

const { Column, HeaderCell, Cell } = Table
const styles = {
  width: 300,
  marginLeft: "auto",
}
const AutoReportTable = props => {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const [request, setRequest] = useState({
    report_on: "",
    report_type: "",
  })
  var tableData = props?.data
    .map((item, index, arr) => {
      // Check if the current item is of type 'PROXY' and it's not the first item
      if (item.nature_of_notice === "PROXY" && index > 0) {
        // Create a new object to modify without affecting the original item
        let newItem = { ...item }

        // List of report keys to update if the previous report is not null
        const reportKeys = [
          "normal_report",
          "interim_report",
          "preliminary_report",
          "canva_report",
          "final_report",
        ]

        // Loop through each key and update if the previous item's corresponding report is not null
        reportKeys.forEach(key => {
          if (arr[index - 1][key] !== null) {
            newItem[key] = "yes" // Update to "yes" if the previous item's report is not null
          }
        })

        return newItem // Return the modified item
      }
      return item // Return the original item if no conditions are met
    })
    .filter(item => item.proxy_report_id === "0") // Apply filter condition after map

  const dispatch = useDispatch()
  const { height, width } = useWindowDimensions()
  const [searchQuery, setsearchQuery] = useState("")
  const handleChangeLimit = dataKey => {
    setPage(1)
    setLimit(dataKey)
  }
  const selectReportData = [
    {
      label: "All",
      value: "1",
    },
    {
      label: "Preliminary",
      value: "2",
    },
    {
      label: "Interim",
      value: "3",
    },
    {
      label: "Final",
      value: "4",
    },
  ]

  const downloadClickFile = (file, natureOfNotice, id) => {
    if (natureOfNotice === "USER") {
      const request = {
        token: props?.token,
        folder: "auto_report",
        filename: file,
      }
      DownloadApiCall(request, "download/single-download", "_blank")
    } else {
      proxyNoticeReportDownload(file, id)
    }
  }

  const SearchAutoreportList = () => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      iDisplayLength: limit,
      reportOn: request?.report_on || "",
      reportType: request?.report_type || "",
      batchName: searchQuery || "",
    }
    dispatch(getAutoReport(requestPayload))
  }

  const proxyNoticeReportDownload = async (file, id) => {
    console.log("Performing proxy report download action.")

    const formData = new FormData()
    formData.append("token", props?.token)
    formData.append("folder", "auto_report")
    formData.append("report_type", file)
    formData.append("id", id)

    try {
      const response = await fetch(
        RouteWithoutApi + "api/download/proxyReport",
        {
          method: "POST",
          body: formData,
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()

      if (data.code === 200 && data.response === "success") {
        const base64Data = data.base64_data // Assuming the key name for the data in the response is `base64_data`
        const binaryString = window.atob(base64Data)
        const len = binaryString.length
        const bytes = new Uint8Array(len)

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }

        const blob = new Blob([bytes], { type: data.content_type }) // Use the content type from the backend
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = data.filename || "defaultReport" // Use the filename from the backend or a default
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)

        ShowNotification("", "Successfully Downloaded!", "success", "Ok")
      } else {
        ShowNotification(
          data.message ? data.message : "File Not Found!",
          "danger"
        )
      }
    } catch (error) {
      ShowNotification(
        error.message ? error.message : "An error occurred!",
        "danger"
      )
    }
  }

  useEffect(() => {
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      page: page,
      iDisplayStart: displayStart,
      reportOn: request?.report_on,
      reportType: request?.report_type,
      iDisplayLength: limit,
      batchName: searchQuery,
    }
    dispatch(getAutoReport(requestPayload))
  }, [page, limit, props?.apiAction?.action])

  const handleSetPriority = async reportId => {
    if (!reportId) {
      ShowNotification("Report ID is missing!", "danger")
      return
    }

    try {
      const url = ApiRoute + "setAutoReportPriority"
      const token = props?.token

      const formData = new FormData()
      formData.append("token", token)
      formData.append("report_id", reportId)

      // Make the API call
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      })

      const data = await response.json()

      if (response.ok && data.code === 200 && data.response === "success") {
        SearchAutoreportList()
        ShowNotification("Priority Updated Successfully!", "success")
      } else {
        ShowNotification(data.message || "Failed to update priority.", "danger")
      }
    } catch (error) {
      console.error("Error setting priority:", error)
      ShowNotification("An error occurred while updating priority.", "danger")
    }
  }

  const handleSetDelete = async (reportId) => {
    if (!reportId) {
      ShowNotification("Report ID is missing!", "danger");
      return;
    }
  
    ShowSwal(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      async (result) => {
        if (result.isConfirmed) {
          try {
            const url = ApiRoute + "deleteReport";
            const token = props?.token;
  
            const formData = new FormData();
            formData.append("token", token);
            formData.append("report_id", reportId);
  
            // Make the API call
            const response = await fetch(url, {
              method: "POST",
              body: formData,
            });
  
            const data = await response.json();
  
            if (response.ok && data.code === 200 && data.response === "success") {
              SearchAutoreportList();
              ShowNotification("Deleted Successfully!", "success");
            } else {
              ShowNotification(data.message || "Failed to delete report.", "danger");
            }
          } catch (error) {
            console.error("Error deleting report:", error);
            ShowNotification("An error occurred while deleting the report.", "danger");
          }
        } 
      }
    );
  };
  
  


  return (
    <div className="mt-4">
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col md={4}>
          <Label>Date & Time:</Label>
          <Input
            type="date"
            name="report_on"
            id="report_on"
            onChange={e => setRequest({ ...request, report_on: e })}
            block
          />
        </Col>
        <Col md={4} style={{ marginTop: "1.5em" }}>
          <Label>Select Report Type:</Label>
          <SelectPicker
            // value={}
            className="mb-3"
            block
            data={selectReportData}
            onChange={e => setRequest({ ...request, report_type: e })}
          />
        </Col>
        <Col md={4} style={{ marginTop: "2em" }}>
          <Input
            placeholder="Enter Batch Name"
            onChange={e => {
              setsearchQuery(e)
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                SearchAutoreportList()
              }
            }}
          />
        </Col>

        <Col md={4} style={{ marginTop: "2em" }}>
          <Button
            type="submit"
            className="my-4"
            // color="green"
            color="primary"
            size="md"
            block
            onClick={SearchAutoreportList}
          >
            Submit
          </Button>{" "}
        </Col>
      </Row>

      <div className="table-responsive mt-4">
        <Table
          height={height - 350}
          data={tableData}
          wordWrap="break-word"
          headerHeight={46}
          loading={props?.tableLoading}
        >
          <Column width={80} align="center">
            <HeaderCell>Sr.No.</HeaderCell>
            <Cell>
              {(rowdata, key) => (
                <>
                  <span>
                    {page === 1 ? key + 1 : limit * (page - 1) + key + 1}
                  </span>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={2}>
            <HeaderCell>Notice</HeaderCell>
            <Cell dataKey="notice_type" />
          </Column>
          <Column flexGrow={2}>
            <HeaderCell>Batch</HeaderCell>
            <Cell dataKey="batch_name" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Report On</HeaderCell>
            <Cell dataKey="report_on" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Normal</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled={rowdata?.normal_report === null}
                        onClick={() => {
                          downloadClickFile(
                            rowdata?.nature_of_notice === "PROXY"
                              ? "normal_report"
                              : rowdata?.normal_report,
                            rowdata?.nature_of_notice,
                            rowdata?.id
                          )
                        }}
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Preliminary</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled={rowdata?.preliminary_report === null}
                        onClick={() =>
                          downloadClickFile(
                            rowdata?.nature_of_notice === "PROXY"
                              ? "preliminary_report"
                              : rowdata?.preliminary_report,
                            rowdata?.nature_of_notice,
                            rowdata?.id
                          )
                        }
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Interim</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <>
                    <WhisperTooltip
                      placement="top"
                      trigger="hover"
                      tooltipMsg="Download File"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                        disabled={rowdata?.interim_report === null}
                        onClick={() =>
                          downloadClickFile(
                            rowdata?.nature_of_notice === "PROXY"
                              ? "interim_report"
                              : rowdata?.interim_report,
                            rowdata?.nature_of_notice,
                            rowdata?.id
                          )
                        }
                      />
                    </WhisperTooltip>
                  </>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Final</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="Download File"
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="green"
                      icon={<FileDownloadIcon />}
                      disabled={rowdata?.final_report === null}
                      onClick={() =>
                        downloadClickFile(
                          rowdata?.nature_of_notice === "PROXY"
                            ? "final_report"
                            : rowdata?.final_report,
                          rowdata?.nature_of_notice,
                          rowdata?.id
                        )
                      }
                    />
                  </WhisperTooltip>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Canva</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="View Canva Report"
                  >
                    <Link
                      to={{
                        pathname:
                          RouteWithoutApi + "autoCanvaReport/" + rowdata?.id,
                      }}
                      target="_blank"
                    >
                      <IconButton
                        size="xs"
                        appearance="primary"
                        color="blue"
                        disabled={rowdata?.final_report === null}
                        icon={<EyeIcon />}
                      />
                    </Link>
                  </WhisperTooltip>
                </>
              )}
            </Cell>
          </Column>{" "}
          <Column flexGrow={1} align="center">
            <HeaderCell>Priority</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="Set Priority"
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="yellow"
                      disabled={rowdata?.thread_id === "1"} // Disable if priority is already set
                      icon={<SortUpIcon />}
                      onClick={() => handleSetPriority(rowdata?.id)} // Call the function to set priority
                    />
                  </WhisperTooltip>
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Delete</HeaderCell>
            <Cell>
              {rowdata => (
                <>
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="Delete Record"
                  >
                    <IconButton
                      size="xs"
                      appearance="primary"
                      color="red"
                      icon={<TrashIcon />}
                      onClick={() => handleSetDelete(rowdata?.id)} // Call the function to set priority
                    />
                  </WhisperTooltip>
                </>
              )}
            </Cell>
          </Column>
        </Table>
        <Pagination
          className="my-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={props.tableCount}
          limitOptions={[50, 100, 200]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.AutoReport.items,
  tableLoading: state.AutoReport.loading,
  tableCount: state.AutoReport.count,
  token: state.Login.token,
})

export default connect(mapStateToProps)(AutoReportTable)
