import axios from "axios"
import { ApiRoute } from "common/ApiRoute"

const api_url = ApiRoute

export const getEmailLog = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"
  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    formData.append(key, value)
  })
  return dispatch => {
    dispatch({ type: "EmailLogBegin" })
    axios
      .post(api_url + "user/email-track-log", formData)
      .then(result => {
        dispatch({
          type: "EmailLogSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "EmailLogFailure",
          data: error,
        })
      })
  }
}

export const getWhatsappLog = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"
  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    formData.append(key, value)
  })
  return dispatch => {
    dispatch({ type: "WhatsappLogBegin" })
    axios
      .post(api_url + "user/whatsapp-track-log", formData)
      .then(result => {
        dispatch({
          type: "WhatsappLogSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "WhatsappLogFailure",
          data: error,
        })
      })
  }
}

export const getSMSLog = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"
  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    formData.append(key, value)
  })
  return dispatch => {
    dispatch({ type: "SMSLogBegin" })
    axios
      .post(api_url + "user/sms-track-log", formData)
      .then(result => {
        dispatch({
          type: "SMSLogSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "SMSLogFailure",
          data: error,
        })
      })
  }
}

export const getCourierLog = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"
  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    formData.append(key, value)
  })
  return dispatch => {
    dispatch({ type: "CourierLogBegin" })
    axios
      .post(api_url + "user/courier-track-log", formData)
      .then(result => {
        dispatch({
          type: "CourierLogSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "CourierLogFailure",
          data: error,
        })
      })
  }
}

export const getBarcodeLog = requestPayload => {
  //   const role_name = role === 2 ? "admin" : role === 1 ? "mediator" : "user"
  let formData = new FormData()
  Object.entries(requestPayload).map(([key, value]) => {
    formData.append(key, value)
  })
  return dispatch => {
    dispatch({ type: "BarcodeLogBegin" })
    axios
      .post(api_url + "user/barcode-track-log", formData)
      .then(result => {
        dispatch({
          type: "BarcodeLogSuccess",
          data: result.data.responseData,
          count: result.data.count,
        })
      })
      .catch(error => {
        dispatch({
          type: "BarcodeLogFailure",
          data: error,
        })
      })
  }
}
