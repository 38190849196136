import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { useParams, useLocation } from "react-router-dom"

import { setBreadcrumbItems } from "../../../store/actions"
import { CustomProvider } from "rsuite"
import { TabBar } from "common/TabBar/TabBar"
import EmailLogTable from "./EmailLogTable"
import WhatsAppLogTable from "./WhatsAppLogTable"
import SMSLogTable from "./SMSLogTable"
import CourierLogTable from "./CourierLogTable"
import BarcodeLogTable from "./BarcodeLogTable"

const UserTrack = props => {
  const location = useLocation()
  const params = useParams()

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Notice List", link: "/user/noticelist" },
    { title: "Notice Details", link: location?.state?.prevPath },
    { title: "Track Details", link: "#" },
  ]

  //   const result = props?.data?.resultData?.filter(
  //     value => parseInt(value.Id) === parseInt(atob(params.id))
  //   )

  const [active, setActive] = useState("email")

  const tabbardata = [
    {
      eventkey: "email",
      lable: "Email log",
    },
    { eventkey: "whatsApp", lable: "WhatsApp log" },
    { eventkey: "SMS", lable: "SMS log" },
    { eventkey: "courier", lable: "Courier log" },
    { eventkey: "barcode", lable: "Barcode log" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems(
      `Track Details - Notice ID: IN${atob(params.id)}`,
      breadcrumbItems
    )
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Track Details - User | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        <TabBar
          style={{ textAlign: "center" }}
          appearance="tabs"
          active={active}
          onSelect={setActive}
          panel={tabbardata}
        />
        {active === "email" && (
          <>
            <EmailLogTable />
          </>
        )}
        {active === "whatsApp" && (
          <>
            <WhatsAppLogTable />
          </>
        )}
        {active === "SMS" && (
          <>
            <SMSLogTable />
          </>
        )}
        {active === "courier" && (
          <>
            <CourierLogTable />
          </>
        )}
        {active === "barcode" && (
          <>
            <BarcodeLogTable />
          </>
        )}
      </CustomProvider>
      {/* {result ? <Details data={result[0]} /> : <h6 className="mt-5" style={{display:"flex", justifyContent: "center"}} >No Direct Access</h6>} */}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  //   data: state.DraftNotice.items,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(UserTrack)
